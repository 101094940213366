import React from 'react';
import { Link } from 'react-router-dom';
import Ab3 from '../../images/home-easy-to-lift-16.jpg';


const ClickHandler = () => {
    window.scrollTo(10, 0);
}


const AboutSectionS3 = () => {
    return (
        <div className="wpo-about-area-s3 section-padding">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 colsm-12">
                        <div className="wpo-about-text">
                            <div className="wpo-about-title">
                                <span className="sub">Melcher Ramps</span>
                                <h2>Made by Hand with <span>High-Quality </span> Craftsmanship</h2>
                            </div>
                            <h5>Our durable, lightweight ramps are designed for effortless loading and unloading, making moves and delivery faster and safer.  </h5>
                            <p>Family-owned for over three generations since 1967, Melcher Manufacturing specializes in light, medium and heavy-duty ramps for a wide variety of uses. We have the ramps you need for any situation, whether you are loading, moving, or delivering equipment or goods.</p>
                            <p>We sell composite fiberglass ramps in over 40 sizes and various capacities. With our state of the art production process, we have the flexibility and creativity to work with you to develop a custom solution. </p>
                            <div className="btns">
                                <Link onClick={ClickHandler} to="/about" className="theme-btn">Discover More</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="wpo-about-img triangle-overlay">
                            <img src={Ab3} alt="composite fiberglass ramps" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutSectionS3;