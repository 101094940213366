
/* Single image */
import img1 from '../images/icon/truck-ramp-couch-sharp-duotone-solid.svg'
import img2 from '../images/icon/person-dolly-sharp-duotone-solid.svg'
import img3 from '../images/icon/vacuum-sharp-duotone-solid.svg'
import img4 from '../images/icon/piano-sharp-duotone-solid.svg'
import img5 from '../images/icon/sportsball-sharp-duotone-solid.svg'
import img6 from '../images/icon/ramp-loading-sharp-duotone-solid.svg'


import Simg1 from '../images/service-single/1.jpg'
import Simg2 from '../images/service-single/2.jpg'
import Simg3 from '../images/service-single/3.jpg'
import Simg4 from '../images/service-single/4.jpg'
import Simg5 from '../images/service-single/5.jpg'
import Simg6 from '../images/service-single/6.jpg'

const Services = [
    {
        id: 1,
        image: img1,
        simag: Simg1,
        title: 'Residential & Commercial Moving',
        description: 'Loading, Stair, and Curb Ramps',
    },
    {
        id: 2,
        image: img2,
        simag: Simg2,
        title: 'Food & Beverage Delivery',
        description: 'Secure Loading and Unloading of Bulk Food and Beverage Products',
    },
    {
        id: 3,
        image: img3,
        simag: Simg3,
        title: 'Janitorial',
        description: 'Loading and Unloading of Janitorial Equipment',
    },
    {
        id: 4,
        image: img4,
        simag: Simg4,
        title: 'Music & Entertainment',
        description: 'Stage Ramps for Moving Equipment On and Off-stage',
    },
    {
        id: 5,
        image: img5,
        simag: Simg5,
        title: 'Athletic Events ',
        description: 'Loading and Unloading of Sports Equipment',
    },
    {
        id: 6,
        image: img6,
        simag: Simg6,
        title: 'Rental Equipment ',
        description: 'Loading and Unloading Items for Residential Use',
    }




];
export default Services;