import React from 'react';
import R1 from '../../images/lightweight-ramp-38.jpg';

const SkillSection = (props) => {
    return (
        <section className={"" + props.hclass}>
            <div className="container section-padding">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="wpo-skill-img">
                            <img src={R1} alt="lightweight ramp" />
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-12 col-12">
                        <div className="wpo-section-title-s2">
                            <p>Why Choose Melcher Ramps</p>
                            <h3>Ramp <span>Design and Workmanship</span> You Can Count On</h3>
                        </div>
                        <p>Many industry leaders specifically request our ramps because no one else makes ramps with long-lasting workmanship and quality like we do. Our ramps are built with reinforced aluminum ends, heavy aluminum side curbs, an easy roll deck surface, and a durable super traction surface that prevents slips in wet and cold weather. They are designed and manufactured to handle the repetitive nature/use of the industries we serve and last for many years of reliable service.</p>
                        <h4>Customer Service for Your Ramps That is Second to None</h4>
                        <p>We stand behind our products 100%, which is why we have been in business over 55 years and have a stellar reputation in the industry. Because we’re based in the U.S., we’re always here if you ever need us for help, knowledge, or ramp service or repairs. </p>
                        <p>Melcher Ramps have made loading and unloading easy and safe for thousands of satisfied customers. Contact us to find out the most suitable ramp for your task or situation.</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SkillSection;