import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Gimg1 from '../../images/bkg-melcher-82.jpg';

const RampResurfacing = () => {

    const location = useLocation(); // Get the current URL path
    const canonicalUrl = `https://www.melcher-ramps.com${location.pathname}`; // Create the canonical URL

    return (
        <Fragment>
            <Helmet>
                <title>Melcher Ramp Resurfacing | Melcher Manufacturing</title>
                <meta name="description" content="Resurfacing your ramp is quick and easy with our resurfacing kits, which refresh the super traction surface to like-new, providing additional years of use for the ramp." />
                <link rel="canonical" href={canonicalUrl} /> {/* Add the canonical tag */}
            </Helmet>
            <Navbar hclass={'wpo-site-header wpo-header-style-3'} />
            <PageTitle pageTitle={'Ramp Resurfacing Kits & Parts'} pagesub={'About'} />
            <div className="wpo-project-single-area section-padding">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-12">
                            <div className="wpo-project-single-wrap">
                                <div className="wpo-project-single-item">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6">
                                            <div className="wpo-project-single-title">
                                                <h3>Extend the Life of your Ramp with a Resurfacing Kit</h3>
                                            </div>
                                            <p>Resurfacing your ramp is quick and easy with our resurfacing kits, which refresh the super traction surface to like-new, providing additional years of use for the ramp.  </p>
                                            <p>See our <a href="/Melcher_Surface_Kit_Literature.pdf" target="_blank">Ramp Resurfacing Instructions – for Melcher Ramps Only</a>. We recommend reading these instructions thoroughly before starting your resurfacing project.</p>
                                            <Link to="/Melcher_Surface_Kit_Literature.pdf" target="_blank" className="theme-btn" >Download Instructions</Link>

                                        </div>
                                        <div className="col-lg-6">
                                            <div className="wpo-project-single-item-quote">
                                                <h3>In addition to resurfacing kits, we sell a variety of parts to refresh or repair your ramp:</h3>
                                                <p className='lh-sm mt-4'>Steel Locking Pins
                                                    <br />Aluminum Curbs
                                                    <br />Aluminum Ends
                                                    <br />Hinge Sets
                                                    <br />Easy Slide Steel Hooks
                                                    <br />Easy Slide Pull Straps</p>
                                                <p className='lh-sm'>All parts come with instructions and the fasteners needed to install them.  <a href="/contact">Contact us today for more information.</a></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="wpo-project-single-main-img pt-5">
                                        <img src={Gimg1} alt="Melcher Manufacturing Composite and Fiberglass Ramps" style={{ width: "100%" }} />

                                    </div>
                                </div>
                                <div className="wpo-project-single-item">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="wpo-project-single-title">
                                                <h3>Conditions required for ensuring proper bonding of materials (IMPORTANT!)</h3>
                                            </div>
                                            <ul>
                                                <li><strong>Temperature</strong> - The ramp and resin materials should be a moderate 70 degrees before starting. Do not apply materials in direct sunlight or on ramp that has been in the sun absorbing heat.</li>
                                                <li><strong>Resin material</strong> - If temperature is above 75 degrees, the resin will dry too fast. If it’s below 55 degrees, the resin will dry too slowly or not dry at all.</li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="wpo-project-single-content-des-right">
                                                <ul>
                                                    <li><strong>Materials needed:</strong>
                                                        <span>
                                                            <ol>
                                                                <li>Polyester (fiberglass) resin and appropriate catalyst (hardener) purchased at your local home improvement center, marina, or fiberglass shop.</li>
                                                                <li>Dispenser with non-skid material.</li>
                                                            </ol>
                                                        </span>
                                                    </li>
                                                    <li><strong>Safety guidelines:</strong>
                                                        <span>
                                                            <ol>
                                                                <li>Read and understand all labels on containers.</li>
                                                                <li>Wear safety glasses and appropriate gloves to protect your hands.</li>
                                                            </ol>
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="wpo-project-single-item">
                                    <div className="wpo-project-single-title">
                                        <h3>Surface Preparation:</h3>
                                    </div>
                                    <ol>
                                        <li>Power wash surface to remove all grease and dirt.</li>
                                        <li>Allow it to dry.</li>
                                        <li>(Recommended) Remove most of the old non-skid material with a disc grinder using a 16-grit disc on the entire surface. Grind to the resin surface, not through it. </li>
                                        <li>Make sure the surface is clean, dry, and dust-free before starting.</li>
                                    </ol>
                                    <div className="wpo-project-single-title">
                                        <h3>Resurfacing:</h3>
                                    </div>
                                    <ol>
                                        <li>Mix one gallon of resin and one ounce of the catalyst using a painter's stir stick in a plastic disposable bucket. NOTE: This should be done quickly to provide the most working time for the resin.</li>
                                        <li>Immediately pour the resin onto the clean surface of the ramp as evenly as possible, then finish spreading the resin with a 4-inch disposable putty knife.</li>
                                        <li>Release the non-skid material from the cardboard dispenser by pulling the tab to release the material over the ramp. For maximum and efficient coverage walk briskly with the dispenser alongside the ramp, starting at one end then going to the other and back and forth until the material completely covers the wet resin. If the non-skid material has not covered all of the ramp, distribute any excess non-skid material by tipping the ramp side to side to cover the light areas of the ramp. Do not spread the non-skid material with your hands or a tool as it will create lumps in the surface of the ramp.</li>
                                        <li>When the resin is dry, broom off excess non-skid material. If in doubt that it is dry, wait overnight.</li>
                                        <li>Dispose of all empty containers and materials according to Federal, State, and Local Regulations.</li>
                                    </ol>
                                    <p>Melcher Manufacturing has no warranty on this product due to the variables in the consumer application process.</p>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer hclass={'wpo-site-footer'} />
            <Scrollbar />
        </Fragment>
    )
};
export default RampResurfacing;