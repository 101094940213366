import React from 'react';
import ContactForm from '../ContactFrom/ContactForm'


const Contactpage = () => {

    return (
        <section className="wpo-contact-pg-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-10 offset-lg-1">
                        <div className="office-info">
                            <div className="row">
                                {/* <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-location-1"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Address</h2>
                                            <p>6017 E. Mission Ave<br/>
                                            Spokane Valley, WA 99212</p>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="col col-xl-6 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-email"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Email Us</h2>
                                            <p><a href="mailto:sales@melcher-ramps.com">sales@melcher-ramps.com</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-xl-6 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-phone-call"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Call Now</h2>
                                            <a href="tel:+15095357626">1-509-535-7626</a> <br />
                                            <a href="tel:+18005414227">1-800-541-4227</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="wpo-contact-title">
                            <h2>Questions?</h2>
                            <p>We would love to hear from you.</p>
                        </div>
                        <div className="wpo-contact-form-area">
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </div>
            <section className="wpo-contact-map-section">
                <div className="wpo-contact-map">
                    <iframe title='comtact-map' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13069.525981075087!2d-117.3250588!3d47.6714233!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x549e1f67705503df%3A0xf1b24e082ad2f1eb!2sMelcher%20Manufacturing!5e1!3m2!1sen!2sus!4v1726597953903!5m2!1sen!2sus"></iframe>
                </div>
            </section>
        </section>
    )

}

export default Contactpage;
