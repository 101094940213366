import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom'; // Import useLocation to get the current path
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle';
import About from '../../components/about/about';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar';

import Ab from '../../images/about.jpg';

const AboutPage = () => {
    const location = useLocation(); // Get the current URL path
    const canonicalUrl = `https://www.melcher-ramps.com${location.pathname}`; // Create the canonical URL

    return (
        <Fragment>
            <Helmet>
                <title>About Melcher Manufacturing</title>
                <meta name="description" content="Because of our history, you get experts in ramp design, manufacturing and materials who can advise you on the best ramp solution for your needs." />
                <link rel="canonical" href={canonicalUrl} /> {/* Add the canonical tag */}
            </Helmet>
            <Navbar hclass={'wpo-site-header wpo-header-style-3'} />
            <PageTitle pageTitle={'About Us'} pagesub={'About'} />
            <About hclass={'wpo-about-area section-padding'} Ab={Ab} />
            <Footer hclass={'wpo-site-footer'} />
            <Scrollbar />
        </Fragment>
    );
};

export default AboutPage;
