import { Link } from 'react-router-dom'
import ramps from '../../api/ramps'
import ContactForm from '../ContactFrom/ContactForm'



const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const BlogSidebar = (props) => {

    const typeDisplayNames = {
        medium: "Medium-Duty Single Ramps",
        heavy: "Heavy-Duty 2-in-1 Ramps",
        light: "Light-Duty Single Ramps",
        easy: "Easy-Slide Ramps"
    };

    return (
        <div className={`col col-lg-4 col-12 ${props.blLeft}`}>
            <div className="blog-sidebar">
                <div className="widget category-widget">
                    <h3>Categories</h3>
                    <ul>
                        {[...new Set(ramps.map(rampItem => rampItem.type))].map((type, index) => (
                            <li key={index}>
                                <Link onClick={ClickHandler} to={`/ramps/${type}`}>
                                    {typeDisplayNames[type] || type} {/* Use custom name if available, otherwise fallback to type */}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
              
              
                
                <div className="widget contact-widget">
                    <h3>Contact Us</h3>
                    <p><strong>Questions?</strong> We'd love to hear from you!</p>
                    <ContactForm />
                </div>
            </div>
        </div>
    )

}

export default BlogSidebar;
