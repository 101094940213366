import { Link, useLocation } from 'react-router-dom';
import MobileMenu from '../MobileMenu/MobileMenu';
import Logo from '../../images/Melcher_Logo.png';
import { connect } from "react-redux";
import { removeFromCart } from "../../store/actions/action";

const Header = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const location = useLocation();  // Get the current location

    const isActive = (path) => {
        return location.pathname === path ? 'current-menu-item' : '';
    }

    return (
        <header id="header" className={props.hclass}>
            <nav className="navigation navbar navbar-expand-lg navbar-light">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                            <MobileMenu />
                        </div>
                        <div className="col-lg-8 col-md-6 col-6">
                            <div className="navbar-wrap">
                                <div className="navbar-header">
                                    <Link onClick={ClickHandler} className="navbar-brand" to="/"><img src={Logo} alt="Melcher Ramps" /></Link>
                                </div>
                                <div id="navbar" className="collapse navbar-collapse navigation-holder">
                                    <button className="menu-close"><i className="ti-close"></i></button>
                                    <ul className="nav navbar-nav mb-2 mb-lg-0">
                                        <li className={isActive("/")}><Link to="/">Home</Link></li>
                                        <li className={isActive("/about")}><Link onClick={ClickHandler} to="/about">About</Link></li>
                                        <li className={isActive("/features")}><Link onClick={ClickHandler} to="/features">Features</Link></li>
                                        <li className={`menu-item-has-children ${location.pathname.startsWith("/ramps") ? "current-menu-item" : ""}`}>
                                            <Link to="/ramps">Ramps</Link>
                                            <ul className="sub-menu">
                                                <li className={isActive("/ramps/heavy")}><Link onClick={ClickHandler} to="/ramps/heavy">Heavy Duty 2-in-1</Link></li>
                                                <li className={isActive("/ramps/medium")}><Link onClick={ClickHandler} to="/ramps/medium">Medium Duty Single</Link></li>
                                                <li className={isActive("/ramps/light")}><Link onClick={ClickHandler} to="/ramps/light">Light Duty Single</Link></li>
                                                <li className={isActive("/ramps/easy")}><Link onClick={ClickHandler} to="/ramps/easy">Easy-Slide</Link></li>
                                            </ul>
                                        </li>
                                        <li className={isActive("/parts")}><Link onClick={ClickHandler} to="/parts">Parts</Link></li>
                                        <li className={isActive("/contact")}><Link onClick={ClickHandler} to="/contact">Contact</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-3 col-3">
                            <div className="header-right">
                                <div className="get-btn">
                                    <Link onClick={ClickHandler} to="/contact" className="theme-btn">Contact Us</Link>
                                </div>
                                <div className="header-socials">
                                    <ul>
                                        <li><Link onClick={ClickHandler} to='https://www.facebook.com/melcherramps'><i className="ti-facebook"></i></Link></li>
                                        {/* <li><Link onClick={ClickHandler} to='#'><i className="ti-instagram"></i></Link></li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    );
};

const mapStateToProps = (state) => {
    return {
        carts: state.cartList.cart,
    };
};

export default connect(mapStateToProps, { removeFromCart })(Header);
