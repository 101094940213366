import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import Hero3 from '../../components/hero3/Hero3';
import AboutS3 from '../../components/AboutSectionS3/AboutSectionS3';
import ServiceSection from '../../components/ServiceSection/ServiceSection';
import SkillSection from '../../components/SkillSection/SkillSection';
import ProjectSection from '../../components/ProjectSection/ProjectSection';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar';
import SImg from '../../images/skill.jpg'
const HomePage = () => {

    const location = useLocation(); // Get the current URL path
    const canonicalUrl = `https://www.melcher-ramps.com${location.pathname}`; // Create the canonical URL

    return (
        <Fragment>
            <Helmet>
                <title>Melcher Manufacturing | Fiberglass and Composite Ramps</title>
                <meta name="description" content="Our durable, lightweight ramps are designed for effortless loading and unloading, making moves and delivery faster and safer." />
                <link rel="canonical" href={canonicalUrl} /> {/* Add the canonical tag */}
            </Helmet>
            <Navbar hclass={'wpo-site-header wpo-header-style-3'} />
            <Hero3 hclass={'wpo-hero-slider wpo-hero-slider-s2'}/>
            <AboutS3 />
            <ServiceSection hclass={"wpo-service-area"} ServiceBtn={true} />
            <ProjectSection hclass={'wpo-project-area'} SectionTitleShow={true} />
            <SkillSection hclass={'wpo-skill-section'} Akimg={SImg}/>
            <Footer hclass={'wpo-site-footer'} />
            <Scrollbar /> 
        </Fragment>
    )
};
export default HomePage;