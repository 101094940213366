import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import BlogSidebar from '../BlogSidebar/BlogSidebar';
import ramps from '../../api/ramps';

// Import all images
import gl39 from '../../images/melcher-39.jpg';
import gl77 from '../../images/melcher-77.jpg';
import gl44 from '../../images/melcher-44.jpg';
import gl49 from '../../images/melcher-49.jpg';
import gl60 from '../../images/melcher-60.jpg';
import gl61 from '../../images/melcher-61.jpg';
import gl33 from '../../images/melcher-33.jpg';
import gl34 from '../../images/melcher-34.jpg';

const RampsList = (props) => {
    const { type } = useParams(); // Capture the type from the URL
    const [filteredRamps, setFilteredRamps] = useState([]);
    const [height, setHeight] = useState('');
    const [capacity, setCapacity] = useState('');

    // Define headings and text based on ramp type
    const rampTypeHeadings = {
        medium: "Medium-Duty Ramps for Loads Up to 3,000 Pounds",
        heavy: "Heavy-Duty 2-in-1 Ramps for Moving Your Heaviest Items",
        light: "Light-Duty Ramps for Short-Haul Loads Up to 1,500 Pounds",
        easy: "Easy-Slide Ramps for Light-Duty Maneuverability",
    };

    const rampTypeDescriptions = {
        medium: "Our Medium Duty, single walk ramps are perfect for loading furniture, electronic equipment, pianos, janitorial and athletic equipment, or other items weighing up to 3,000 pounds. Medium Duty ramps come in 30-inch or 36-inch widths and a variety of lengths to meet all your loading height needs, from traversing a curb to loading a trailer with a 45-inch deck height. These ramps come with our super traction surface and reinforced aluminum ends and side curbs providing safe, sure footing.  ",
        heavy: "The Dual Ramp is our most popular model with movers across North America because of its 36-inch width for loading large household goods. The ramp can also be separated into two 18-inch-wide halves for loading autos, ATVs and UTVs,  or other items with wider wheel lines. When joined together the ramp can handle loads up to 5,000 pounds. These ramps come with our super traction surface and reinforced aluminum ends and side curbs providing safe, sure footing. Durable, economical, rugged, lightweight and easy to handle, our heavy-duty dual ramp is available in six lengths to suit your job requirements and truck bed height.",
        light: "Ideal for short-haul deliveries, our light-duty ramps are easy for one driver or mover to handle. Hand trucks will roll easily and smoothly, while the super traction surface provides safe, sure footing. Light Duty ramps also come in Dual Ramp (DR) models that can be separated into 13-inch halves for loading lawn tractors, golf carts, small ATVs and UTVs,  or other small vehicles. ",
        easy: "Our Easy-Slide ramps are perfect for daily delivery of bulk food and other goods. These ramps provide a 24-inch wide usable surface and come with our super traction surface and reinforced aluminum ends and side curbs providing safe, sure footing.  They can be ordered with either an open carrier or enclosed carrier designed to be installed underneath the bed of a truck or trailer, allowing the driver to have  easy, in-and-out sliding of the ramp at each stop.  The safety retaining latch that comes with each carrier keeps the ramps secure between stops.    ",
    };

    const heading = rampTypeHeadings[type] || "All Ramps";
    const description = rampTypeDescriptions[type] || "Browse through our wide selection of ramps to find the perfect one for your needs.";

    // Determine which images to display based on the ramp type
    const getImagesForType = (type) => {
        switch (type) {
            case 'medium':
                return [gl44, gl49];
            case 'heavy':
                return [gl39, gl77];
            case 'light':
                return [gl60, gl61];
            case 'easy':
                return [gl33, gl34];
            default:
                return [gl39, gl77]; // Fallback images
        }
    };

    const [gl1, gl2] = getImagesForType(type);

    // Filter the ramps based on the type in the URL and input values
    useEffect(() => {
        const filterRamps = () => {
            const filtered = ramps.filter(ramp => {
                // Ensure we only show ramps of the selected type from the URL
                const matchesType = type ? ramp.type === type : true;

                // Check capacity (exact match or greater)
                const matchesCapacity = capacity === '' || ramp.capacity >= capacity;

                // Check if height range includes the user input
                const rampHeightRange = ramp.height.split('-');
                const minHeight = parseInt(rampHeightRange[0]);
                const maxHeight = parseInt(rampHeightRange[1] || rampHeightRange[0]);
                const matchesHeight = height === '' || (height >= minHeight && height <= maxHeight);

                return matchesType && matchesCapacity && matchesHeight;
            });

            setFilteredRamps(filtered);
        };

        filterRamps();
    }, [height, capacity, type]);

    return (
        <section className="wpo-blog-single-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-8 col-12 post">

                        <h2>{heading}</h2>
                        <p>{description}</p>

                        {type && (
                            <a
                                href={`/melcher-${filteredRamps[0]?.type}-safety-manual.pdf`}
                                target="_blank"
                                rel="noreferrer"
                                className="theme-btn"
                            >
                                Download Safety &amp; Use Guide
                            </a>
                        )}

                        <div className="gallery">
                            <div>
                                <img src={gl1} alt="Melcher Manufacturing Composite and Fiberglass Ramps" />
                            </div>
                            <div>
                                <img src={gl2} alt="Melcher Manufacturing Composite and Fiberglass Ramps" />
                            </div>
                        </div>

                        <p>Enter the height and capacity of the ramp you need:</p>

                        <div className="row mb-3">
                            <div className="col-md-6">
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Enter Work Height (in inches)"
                                    value={height}
                                    onChange={(e) => setHeight(e.target.value)}
                                />
                            </div>
                            <div className="col-md-6">
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Enter Capacity (in lbs)"
                                    value={capacity}
                                    onChange={(e) => setCapacity(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="row table-header">
                            <div className="col-12 col-md">Model</div>
                            {!type && <div className="col-12 col-md">Type</div>}
                            <div className="col-12 col-md">Length</div>
                            <div className="col-12 col-md">Width</div>
                            <div className="col-12 col-md">Work Height</div>
                            <div className="col-12 col-md">Capacity</div>
                            <div className="col-12 col-md">Weight</div>
                        </div>

                        {filteredRamps.length > 0 ? (
                            filteredRamps.map((ramp, index) => (
                                <div className="row table-row" key={index}>
                                    <div className="col-12 col-md">{ramp.model}</div>
                                    {!type && (
                                        <div className="col-12 col-md text-capitalize">
                                            <Link to={`/ramps/${ramp.type}`}>
                                                {ramp.type}
                                            </Link>
                                        </div>
                                    )}
                                    <div className="col-12 col-md">{ramp.length}'</div>
                                    <div className="col-12 col-md">{ramp.width}"</div>
                                    <div className="col-12 col-md">{ramp.height}"</div>
                                    <div className="col-12 col-md">{ramp.capacity} lbs</div>
                                    <div className="col-12 col-md">{ramp.weight} lbs</div>
                                </div>
                            ))
                        ) : (
                            <div>No ramps match your criteria</div>
                        )}

                    </div>
                    <BlogSidebar blLeft={props.blLeft} />
                </div>
            </div>
        </section>
    );
};

export default RampsList;
