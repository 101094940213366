import f1 from '../images/features/lightweight.jpg'
import f2 from '../images/features/aluminum.jpg'
import f3 from '../images/features/solid.jpg'
import f4 from '../images/features/ramp-joint.jpg'
import f5 from '../images/features/pin.jpg'
import f6 from '../images/features/traction.jpg'
import f7 from '../images/features/smooth.jpg'
import f8 from '../images/features/maneuverability.jpg'

const Services = [
    {
        id: 1,
        title: 'Lightweight Yet Strong ',
        description: 'You can handle heavier loads with a lighter-weight ramp. Because Melcher Ramps are constructed of fiberglass, marine plywood, and aluminum, they provide a high strength-to-weight ratio.',
        image: f1,
    },
    {
        id: 2,
        title: 'Extruded Aluminum Ends & Sidecurbs ',
        description: 'Heavy gauge extended aluminum ends and sidecurbs add durability and ensure your ramp has a long life.',
        image: f2,
    },
    {
        id: 3,
        title: 'Rugged Construction ',
        description: 'You get a solid feeling underfoot due to Melcher\'s reinforced composite construction, which allows natural flexing so that the ramp always returns to its original shape. This flexing also creates less wear and tear on the ramp users.',
        image: f3,
    },
    {
        id: 4,
        title: 'Dual Ramp Joint',
        description: 'The Joint hinge on the Auto Loader and Dual Ramp slips in and out easily so you can separate the ramp into two halves or fasten the halves together firmly and securely to meet your needs.',
        image: f4,
    },
    {
        id: 5,
        title: 'Secure Attachment ',
        description: 'Each Melcher Ramp comes with a secure attachment to ensure complete safety and stability when in use.  A steel locking pin is provided with our standard models while our EZSlide models are equipped with steel hooks to secure the ramp safely and easily to the truck or trailer. ',
        image: f5,
    },
    {
        id: 6,
        title: 'Super Traction',
        description: 'The slip resistant super traction surface enables safer loading whether the surface is wet or dry.  This durable surface will withstand thousands of hours of use and, if needed the ramp can quickly and easily be resurfaced to make your ramp like new and add years of service life.',
        image: f6,
    },
    {
        id: 7,
        title: 'Smooth and Quiet Rolling',
        description: 'Wheels and casters roll more smoothly and quietly on our ramp surface, reducing friction and requiring less force to push and pull items up and down our ramp. ',
        image: f7,
    },
    {
        id: 8,
        title: 'Maneuverability',
        description: 'Dollys and hand trucks can be maneuvered very easily on our ramps, making adjustments to travel paths quick and easy.  ',
        image: f8,
    }




];
export default Services;