import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import RampFeaturesSection from '../../components/RampFeaturesSection/RampFeaturesSection';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar';


const FeaturesPage = () => {

    const location = useLocation(); // Get the current URL path
    const canonicalUrl = `https://www.melcher-ramps.com${location.pathname}`; // Create the canonical URL

    return (
        <Fragment>
            <Helmet>
                <title>Ramp Features | Melcher Manufacturing</title>
                <meta name="description" content="We are proud to offer ramps that are manufactured with features that make them effortless to use and help you perform your tasks as easily and efficiently as possible." />
                <link rel="canonical" href={canonicalUrl} /> {/* Add the canonical tag */}
            </Helmet>
            <Navbar hclass={'wpo-site-header wpo-header-style-3'} />
            <PageTitle pageTitle={'Features'} pagesub={'Service'} />
            <RampFeaturesSection hclass={"wpo-service-area section-padding"} ServiceBtn={true} />
            <Footer hclass={'wpo-site-footer'} />
            <Scrollbar />
        </Fragment>
    )
};
export default FeaturesPage;
