import React from 'react';

// image

import Abd1 from '../../images/melcher-ramps-72.jpg'
import Abd2 from '../../images/Hardan-Ramp-pic.jpg';
import chris from '../../images/chris-78.jpg'

const about = (props) => {
    return (
        <div className={"" +props.hclass}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 colsm-12">
                        <div className="wpo-about-text">
                            <div className="wpo-about-title">
                                <span className="sub">Family-Owned and Operated Since 1967 </span>
                                <h2>A <span>Ramp</span> Company You Can Depend On</h2>
                            </div>
                            <p>At Melcher Manufacturing, we thrive on being a small, family-run business because it assures our customers that they can work with us directly and we’ll be here when they need us. We’re easy to reach and we’re responsive. Because of our history, you get experts in ramp design, manufacturing and materials who can advise you on the best ramp solution for your needs.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="wpo-about-img triangle-overlay">
                            <img src={chris} alt="Chris Hardan Owner of Melcher Manufacturing" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container section-padding">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 colsm-12">
                        <div className="wpo-about-text">
                            <div className="wpo-about-title">
                                <h4>History of Delivering  <span>Innovative Products</span></h4>
                            </div>
                            <p>Our company was founded by Glee Melcher, owner Chris Hardan’s grandfather. Glee was a jack of all trades. Before starting Melcher Manufacturing, he trained to be an English teacher, worked as a farmer and served as a state senator in Washington state. He was an inventor who always came up with ideas for new products. In addition to the Melcher ramp, he invented the highly successful first moving irrigation sprinkler system for irrigating farmland.</p>
                            <p>Glee came up with the idea for the fiberglass ramp after being introduced to the material in the 1950s. Glee was fascinated with fiberglass and wanted to find practical uses for it. His first idea was brushes for street sweepers and car washes.  He tried other products, but nothing stuck until he developed the ramp, building fiberglass I-beams and gluing them to a piece of plywood. The design worked and sold, evolving over the years to the designs we have today.     </p>
                            <p>Chris’s Dad Wayne joined the business right out of college in the early 1970’s. He left for a brief stint with the local power company but soon returned to Melcher and went on to run it for over 40 years. While Glee had the idea behind Melcher Manufacturing, Wayne became the backbone of the company and is the reason for the past 40+ years of success. He streamlined the manufacturing processes and expanded into new markets producing other fiberglass products. He took the vision that Glee had for the company and built it into the thriving business it is today.     </p>
                            <p>With the retirement of Wayne in 2024, Chris took over after three years working side by side with his father to learn the business.  Before joining Melcher, Chris worked as a structural engineer, designing bridges for over 20 years, and he plans to use that expertise to continue the legacy of constant innovation and improvement of our ramps.  With the third generation taking over, you can be sure the same Melcher quality and service will be maintained.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="wpo-about-img">
                            <img src={Abd1} alt="Melcher composite and fiberglass ramps for moving" />
                                <div className="wpo-about-item">
                                        <img src={Abd2} alt="generations of experience" />
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default about;

