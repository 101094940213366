import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import ContactForm from '../../components/ContactFrom/ContactForm'
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar';

import { Link } from 'react-router-dom';
import Ab3 from '../../images/home-easy-to-lift-16.jpg';


const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const ContactPage = () => {

    const location = useLocation(); // Get the current URL path
    const canonicalUrl = `https://www.melcher-ramps.com${location.pathname}`; // Create the canonical URL

    return (
        <Fragment>
            <Helmet>
                <title>Get 10% off Melcher Ramps from FCD</title>
                <meta name="description" content="Get 10% off Melcher Ramps, the ramp that elevates your business." />
                <link rel="canonical" href={canonicalUrl} /> {/* Add the canonical tag */}
            </Helmet>
            <Navbar hclass={'wpo-site-header wpo-header-style-3'} />
            <PageTitle pageTitle={'Get 10% Off Melcher Ramps'} pagesub={'Contact'} />
            <div className="wpo-about-area-s3 pt-120 pb-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 colsm-12">
                            <div className="wpo-about-text">
                                <div className="wpo-about-title">
                                    <span className="sub">Melcher Ramps</span>
                                    <h2>The Ramp That <span>Elevates </span> Your Business</h2>
                                </div>
                                <h5>Our durable, lightweight ramps are designed for effortless loading and unloading, making moves and delivery faster and safer.  </h5>
                                <p>Melcher Manufacturing specializes in light, medium and heavy-duty ramps for a wide variety of uses. We have the ramps you need for any situation, whether you are loading, moving, or delivering equipment or goods.</p>
                                <p>We sell composite fiberglass ramps in over 40 sizes and various capacities. With our state of the art production process, we have the flexibility and creativity to work with you to develop a custom solution. </p>
                                <div className="btns">
                                    <Link onClick={ClickHandler} to="/ramps" className="theme-btn">View Ramps</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="wpo-about-img triangle-overlay">
                                <img src={Ab3} alt="composite fiberglass ramps" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container wpo-contact-pg-section">
                <div className="row">
                    <div className="col col-lg-10 offset-lg-1">

                        <div className="wpo-contact-title">
                            <h2>Want 10% Off?</h2>
                            <p>We are offering 10% off to readers of Facility Cleaning Decisions Magazine. Contact us and mention the ad to claim your discount.</p>
                        </div>
                        <div className="wpo-contact-form-area">
                            <ContactForm />
                        </div>
                    </div>

                </div>
                <div className="office-info">
                    <div className="row">

                        <div className="col col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="office-info-item">
                                <div className="office-info-icon">
                                    <div className="icon">
                                        <i className="fi flaticon-email"></i>
                                    </div>
                                </div>
                                <div className="office-info-text">
                                    <h2>Email Us</h2>
                                    <p><a href="mailto:sales@melcher-ramps.com">sales@melcher-ramps.com</a></p>
                                </div>
                            </div>
                        </div>
                        <div className="col col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="office-info-item">
                                <div className="office-info-icon">
                                    <div className="icon">
                                        <i className="fi flaticon-phone-call"></i>
                                    </div>
                                </div>
                                <div className="office-info-text">
                                    <h2>Call Now</h2>
                                    <a href="tel:+15095357626">1-509-535-7626</a> <br />
                                    <a href="tel:+18005414227">1-800-541-4227</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer hclass={'wpo-site-footer'} />
            <Scrollbar />
        </Fragment>
    )
};
export default ContactPage;





