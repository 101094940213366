import React from "react";
import SectionTitle from "../SectionTitle/SectionTitle";
import Services from "../../api/Features";



const RampFeaturesSection = (props) => {

    const { SectionTitleShow = true, } = props

    return (
        <div className={"" + props.hclass}>
            <div className="container">
                {SectionTitleShow && (
                    <div className="row">
                        <SectionTitle subtitle={'Composite and Fiberglass Ramps'} title={'Qualities That Make Our Ramps '} titleColor={'Stand Out'} />
                        <h5 className="text-center mb-5 px-5">We are proud to offer ramps that are manufactured with features that make them effortless to use and help you perform your tasks as easily and efficiently as possible.</h5>
                    </div>
                )}
                <div className="row align-items-center">
                    {Services.map((service, item) => (
                        <div className="col-lg-6 col-md-6 col-12" key={item}>
                            <div className="wpo-service-item features d-flex">
                                <div className="icon">
                                    <i><img src={service.image} alt="{service.title}" /></i>
                                </div>
                                <div className="desc">
                                <h2>{service.title}</h2>
                                <p>{service.description}</p> 
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>


    );
}

export default RampFeaturesSection;


